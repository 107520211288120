import { defineRoutes } from '@kestrel/router';

export const routes = defineRoutes().add({
	path: '/sandbox',
	component: () => import.meta.env.PROD
		? import('./pages/404.svelte')
		: import('./pages/sandbox/Sandbox.svelte'),
	/*         */
	template: import.meta.env.PROD
		? undefined
		: import('./templates/sandbox_template.html?raw')
})
	.add({
		path: '/signup',
		component: () => import('./pages/signup/SignUp.svelte'),
		server: () => import('./pages/signup/SignUpServer.ts')
	})
	.add({
		path: '/signup/registrationFlow',
		component: () => import('./pages/signup/registrationFlow/RegistrationSignUpFlow.svelte'),
		server: () => import('./pages/signup/registrationFlow/RegistrationSignUpFlowServer.ts'),
		template: () => import('./templates/focused_dialog.html?raw')
	}).add({
		path: '/signup/registrationFlow/new',
		component: () => import('./pages/signup/registrationFlow/RegistrationSignUpFlowNew.svelte'),
		server: () => import('./pages/signup/registrationFlow/RegistrationSignUpFlowServer.ts'),
		template: () => import('./templates/focused_dialog_new.html?raw')
	}).add({
		path: '/signup/success',
		component: () => import('./pages/signup/success/SignUpSuccessBasicSheet.svelte'),
		server: () => import('./pages/signup/success/SignUpSuccessBasicSheetServer.ts')
	}).add({
		path: '/signup/registrationFlow/success/old',
		component: () => import('./pages/signup/registrationFlow/success/SignUpSuccessBasicFocusDialogOld.svelte'),
		server: () => import('./pages/signup/registrationFlow/success/SignUpSuccessBasicFocusDialogServer.ts'),
		template: () => import('./templates/focused_dialog.html?raw')
	}).add({
		path: '/signup/registrationFlow/success',
		component: () => import('./pages/signup/registrationFlow/success/SignUpSuccessBasicFocusDialog.svelte'),
		server: () => import('./pages/signup/registrationFlow/success/SignUpSuccessBasicFocusDialogServer.ts'),
		template: () => import('./templates/focused_dialog_new.html?raw')
	}).add({
		path: '/signup/error',
		component: () => import('./pages/signup/error/SignUpErrorBasicSheet.svelte')
	}).add({
		path: '/signup/registrationFlow/error/old',
		component: () => import('./pages/signup/registrationFlow/error/SignUpErrorBasicFocusDialogOld.svelte'),
		server: () => import('./pages/signup/registrationFlow/error/SignUpErrorBasicFocusDialogServer.ts'),
		template: () => import('./templates/focused_dialog.html?raw')
	}).add({
		path: '/signup/registrationFlow/error',
		component: () => import('./pages/signup/registrationFlow/error/SignUpErrorBasicFocusDialog.svelte'),
		server: () => import('./pages/signup/registrationFlow/error/SignUpErrorBasicFocusDialogServer.ts'),
		template: () => import('./templates/focused_dialog_new.html?raw')
	}).add({
		path: '/pointsInfoBannerSheet',
		component: () => import('./pages/points-info-banner/PointsInfoBannerSheet.svelte'),
		server: () => import('./pages/points-info-banner/PointsInfoBannerSheetServer.ts')
	}).add({
		path: '/teaser/raffle',
		component: () => import('./pages/teaser/raffle/birthday/Raffle.svelte'),
		server: () => import('./pages/teaser/raffle/birthday/RaffleServer.ts')
	}).add({
		path: '/entrypage',
		component: () => import('./pages/teaser/product/ProductTeaser.svelte'),
		server: () => import('./pages/teaser/product/ProductTeaserServer.ts')
	}).add({
		path: '/productlist',
		component: () => import('./pages/teaser/product/ProductTeaser.svelte'),
		server: () => import('./pages/teaser/product/ProductTeaserServer.ts')
	}).add({
		path: '/meinkonto',
		component: () => import('./pages/teaser/customer/CustomerTeaser.svelte'),
		server: () => import('./pages/teaser/customer/CustomerTeaserServer.ts')
	}).add({
		path: '/basket',
		component: () => import('./pages/teaser/customer/CustomerTeaser.svelte'),
		server: () => import('./pages/teaser/customer/CustomerTeaserServer.ts')
	}).add({
		path: '/pointsInfoBanner',
		component: () => import('./pages/points-info-banner/PointsInfoBanner.svelte'),
		server: () => import('./pages/points-info-banner/PointsInfoBannerServer.ts')
	})
	.add({
		path: '/gateway/subscribe',
		methods: () => import('./api/gateway/Subscribe.ts')
	}).add({
		path: '/internal/health',
		methods: () => import('./api/internal/Health.ts')
	}).add({
		path: '/internal/info',
		methods: () => import('./api/internal/Info.ts')
	}).add({
		path: '/internal/prometheus',
		methods: () => import('./api/internal/Prometheus.ts')
	})
	.add({
		path: '/gateway/generateCustomerIdentityUrl',
		methods: () => import('./api/gateway/GenerateCustomerIdentityUrl.ts')
	})
	.add({
		path: '/gateway/pointsInfoBannerContext',
		methods: () => import('./api/gateway/PointsInfoBannerContext.ts')
	}).build();
